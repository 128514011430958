import React, { useState } from 'react'
import './process.css';

function Process() {

  const [Greeting, setGreeting] = useState(false)

  return (
    <div className='pbody'>
      <div class="pcontainer">
   <div class="header" style={{color:'#BB9E03'}}>
    <i class="fas fa-seedling">
    </i>
    THE SMART GRAINS
   </div>
   <div class="subheader">
   India’s largest B2B platform to provide start to end solution for farmers and traders
   </div>
   <div class="process">
    <div class="process-item" data-aos="fade-right">
     <img alt="Image of a tractor in a field" height="100" src="images/mission.png" width="100"/>
     <h3>
     1000+
     </h3>
     <p>
     Farmers
     </p>
    </div>
    <div class="process-item" data-aos="fade-right">
     <img alt="Image of hands planting a seedling" height="100" src="images/i2.png" width="100"/>
     <h3>
     200+
     </h3>
     <p>
     Traders
     </p>
    </div>
    <img className="remove" onClick={()=>setGreeting(true)} data-aos="flip-right" alt="Image of a wooden crate filled with vegetables" class="center-image" height="300" src="icons/fm.png" width="300"/>
    <div class="process-item" data-aos="fade-left">
     <img alt="Image of a field being harvested" height="100" src="https://cdn.pixabay.com/photo/2022/06/10/12/46/reaper-binder-7254433_1280.jpg" width="100"/>
     <h3>
     1000k+
     </h3>
     <p>
     Matric Tones
     </p>
    </div>
    <div class="process-item" data-aos="fade-left">
     <img alt="Image of a processing plant" height="100" src="icons/map.jpg" width="100"/>
     <h3>
     7+
     </h3>
     <p>
     States
     </p>
    </div>
   </div>
   <div class="dashed-line">
   </div>
  
  </div>

{Greeting&&<div style={{
  position:'fixed',
  top:0,
  bottom:0,
  left:0,
  right:0,
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  zIndex:1
}} onClick={()=>setGreeting(false)}>
<img style={{
  height:'90vh',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}} src='icons/gr2.png' alt='img'/>
</div>}




    </div>
  )
}

export default Process
