import React, { useState } from 'react'

const Career = () => {

  // const [result, setResult] = React.useState("Submit");

  // const onSubmit = async (event) => {
  //   event.preventDefault();
  //   setResult("Sending....");
  //   const formData = new FormData(event.target);

  //   formData.append("access_key", "79f4ee02-3a81-4afe-931c-4a5d1b09a3da");

  //   const response = await fetch("https://api.web3forms.com/submit", {
  //     method: "POST",
  //     body: formData
  //   });

  //   const data = await response.json();

  //   if (data.success) {
  //     setResult("Form Submitted Successfully");
  //     event.target.reset();
  //   } else {
  //     console.log("Error", data);
  //     setResult(data.message);
  //   }
  // };

  const [name, setname] = useState('');
  const [ds, setds] = useState('');
  const [mobile, setmobile] = useState('')

  const data =()=>{
    window.open(`mailto:Hr@thesmartgrains.in?subject=Application%20for%20${ds}%20-%20-%20${name}&body=write%20a%20%20message%20hare...`)
    setname('')
    setds('')
    setmobile('')
  }


  return (
    <div className='mbody' style={{background:"#ffffff",paddingBottom:10}} >
    <div class="mheader">
 <img alt="Logo" height="50" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width="50"/>
 <h1 style={{color:'#BB9E03'}}>
  Career Opportunities
 </h1>
</div>
<div className='column' style={{display:"flex",width:"70%",margin:"auto",padding:20}}>
<section className='p0' style={{maxWidth: 900, margin: "auto",padding:20,textAlign:'justify',marginRight:50}}>
        <h2 style={{color: "#2c3e50",marginTop:0}}>Join Our Team <i className='fa-solid fa-people-group'></i></h2>
        <p style={{color:"#555",lineHeight:1.35}}>
            At The Smart Grains, we believe in empowering farmers and bridging the gap between agriculture and technology. If you are passionate about making a difference in the agricultural sector and are looking for a fulfilling career, we would love to have you on our team.
        </p>
        <p style={{color:"#555",lineHeight:1.35}}>
            We offer a dynamic and inclusive work environment where your ideas are valued, and your growth is our priority. Explore the  opportunities  and become a part of our mission to transform the agricultural landscape.
        </p>
    </section>
    {/* <form onSubmit={onSubmit}> */}
  <div style={{flex:1}}></div>
  <div style={{background:"#f9f5f0",padding:20,borderRadius:10}}>
      <div style={{flex:1,display:"flex",flexDirection:"column",textAlign:"left",padding:3}}>
        <label style={{color:"grey",fontSize:12,margin:'20px 0 5px 0',width:250}}>Name</label>
        <input onChange={(e)=>setname(e.target.value)} value={name} name="name" type='text' style={{padding:5,margin:'2px 0 8px'}}></input>
        <label style={{color:"grey",fontSize:12,margin:'20px 0 5px 0'}}>Mobile No</label>
        <input onChange={(e)=>setmobile(e.target.value)} value={mobile} name="mobileNo" type='number' style={{padding:5,margin:'2px 0 8px'}}></input>
        <label style={{color:"grey",fontSize:12,margin:'20px 0 5px 0'}}>Designation</label>
        <input onChange={(e)=>setds(e.target.value)} value={ds} name="text" type='text' style={{padding:5,margin:'2px 0 8px'}}></input>
        {/* <label style={{color:"grey",fontSize:12,margin:'20px 0 5px 0'}}>Upload Resume</label>
        <input onChange={(e)=>setname(e.target.value)} value={name} name="Resume" type='file' style={{padding:5,margin:'2px 0 8px'}}></input> */}
        <div style={{width:"100%",textAlign:'center'}}>
        <button onClick={data} style={{margin:10,background:'#BB9E03',padding:'8px 20px'}}>Upload Resume With Email</button>
        </div>
  </div>
  </div>
  {/* </form> */}
</div>
  {/* <div style={{width:"100%"}}>
    <div style={{color:"blue",border:"2px solid blue",borderRadius:5,width:"min-content",padding:"5px 15px" ,margin:"5px auto"}}>Apply</div>
  </div> */}
</div>
  )
}

export default Career