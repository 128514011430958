import React, { useState } from 'react'
import './navbar.css';


const Navbar = ({scroll}) => {
  const [show, setshow] = useState(false)
  const [active, setActive] = useState('/');

  const toggle=()=>{
    const element = document.getElementById('mobile-nav');
    console.log(element)
    !show?element.classList.add('hide-menu'):element.classList.remove('hide-menu');
    setshow(!show)
  }


  return (  
    <>
    <div style={{position:"relative"}}>
          <div class="navbar Desktop">
          <div class="logo">
          <img className='logo-img' src={"icons/logo.png"} alt='img'/>
          </div>

          <div class="menu" style={{marginLeft:65}}>
          <a onClick={() =>scroll('Home') } href="#">
          Home
          </a>
          <a onClick={() => (scroll('About'))} href="#">
          About
          </a>
          <a onClick={() => (scroll('Mission'))} href="#">
          Mission
          </a>
          <a onClick={() => (scroll('Vision'))} href="#">
          Vision
          </a>
          <a onClick={() => (scroll('Services'))} href="#">
          Services
          </a>
          {/* <a onClick={()=>window.location.href='/Career.html'}> */}
          <a onClick={() => (scroll('Career'))} href="#">
          Career
          </a>
          <a onClick={() => (scroll('Contact'))} href="#">
          Contact Us
          </a>
          </div>
          <div class="menu">
          <i class="fa-solid fa-phone" style={{marginRight:5}}></i>
          <p>+91 88155 59387</p>
          {/* <a onClick={()=>(scroll('Apk'))} className="btn" href="#">
          Get App
          </a> */}
          </div>
        

          </div>

          <div className='Mobile'>
        <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",padding:"0px 15px" ,zIndex:5,position:"relative"}}>
          <img width={40} src={'icons/logo.png'} style={{objectFit:"cover",padding:10}} alt='logo'/>
          <i onClick={toggle} style={{fontSize:20}} className={show?'fa fa-xmark':'fa fa-bars'}/>
        </div>
    
      </div>
 
    
    </div >
    <div id='mobile-nav' className={`mobile-navbar  ${show?'display':''}`}>
       <ul >
       <li  className={active==="/"?'active':'nav link'} to="/">Home</li> 
       <li onClick={() => (setshow(false),scroll('About'))}  className={active==="/About"?'active':'nav link'}>About</li>
       <li onClick={() => (setshow(false),scroll('Mission'))} className={active==="/Quality"?'active':'nav'}>Mission</li>
       <li onClick={() => (setshow(false),scroll('Vision'))} className={active==="/Contact"?'active':'nav'}>Visions</li>
       <li onClick={() => (setshow(false),scroll('Services'))} className={active==="/Quality"?'active':'nav'}>Services</li>
       <li onClick={() => (setshow(false),scroll('Contact'))} className={active==="/Quality"?'active':'nav'}>Contact Us</li>
       <div  class="menu">
       {/* <i class="fa-solid fa-phone" style={{marginRight:5}}></i>

        <p>+91 89596 90512</p> */}
     {/* <a onClick={()=>(scroll('Apk'),setshow(false))} className="btn" href="#">
     Get App
     </a> */}
     </div>
   </ul>
     </div>
   </>
  )
}

export default Navbar
