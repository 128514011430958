import React from 'react'
import './about.css'

function About() {
  return (
    <div>
      <div class="container">
   <div class="content">
    <div class="header">
     <img alt="Farmor logo" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width="20"/>
     <span style={{color: '#BB9E03', fontWeight: 'bold',marginLeft:10}}>
      About
     </span>
    </div>


    <h1 style={{color:'#BB9E03'}} data-aos="fade-right">
     The 
     <br/>
     Smart Grains
    </h1>
    <p>
    At The Smart Grains, we are at the forefront of B2B grain trading, connecting producers and buyers through a seamless, reliable, and efficient platform.
    </p>
    
    <div class="section">
     <img alt="Food Beverage icon" height="50" src="images/mv.png" width="80"/>
     <div>
      <h3>
      What We Do
      </h3>
      <p>
      We specialize in sourcing high-quality grains directly from trusted producers and supplying them to manufacturers, processors, and food companies across the globe.
      </p>
     </div>
    </div>
    <div class="section"  data-aos="fade-right">
     <img alt="Vegetables &amp; Fruits icon" height="50" src="images/mv.png" width="50"/>
     <div>
      <h3>
      Our Goal
      </h3>
      <p>
      Our Goal is to empower grain producers and buyers with the resources, insights, and logistical support needed to make informed trading decisions.
      </p>
     </div>
    </div>
    
    <div class="section" data-aos="fade-right">
     <img alt="Animal Nutrition icon" height="50" src="images/mv.png" width="50"/>
     <div>
      <h3>
      Why Choose Us
      </h3>
      <p>
      With a All India network of suppliers and buyers, we offer a broad reach while maintaining in-depth knowledge of local markets and regulatory environments.
      </p>
     </div>
    </div>
    <div class="section">
     <img alt="Agriculture &amp; Foods icon" height="50" src="images/mv.png" width="50"/>
     <div>
      <h3>
      Our values
      </h3>
      <p>
      We envision a future where global grain trade is more efficient, sustainable, and beneficial to all parties involved.
      </p>
     </div>
    </div>
   </div>
   <div class="image-section">
    <img alt="Image of a farm with rows of crops" style={{width:'80%',height:'80%'}} src="images/header1.avif"/>
    <img alt="Image of a tractor in a field" class="small-image" style={{width:'400px',height:'400px'}} src="images/header2.avif"/>
   </div>
  </div>
    </div>
  )
}

export default About
