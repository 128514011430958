import React from 'react'

const Vision = () => {
    return (
        <div>
        <div style={{background:"white"}}>
          <div className="ocontainer">
            <div className='column' style={{display:'flex',alignItems:"center"}}>
                 <div data-aos="fade-right" style={{flex:1,padding:"10"}}>
              <img className='w100' width={290} src='images/mission.png'></img>
              <img className='w100' width={290} src='images/vision.png'></img>
                  </div>
                 <div data-aos="fade-left"  style={{flex:1}}>
                    <div className='oheader' style={{display:'flex',alignItems:"center"}}>
                        <img alt="Logo" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width={20} height={40} />
                        <h2 style={{color:'#BB9E03'}}>Our Vision</h2>
                    </div>
                    <div style={{textAlign: "left" }}>
                    <h3>Comprehensive Trading</h3>
                    <ul>
                        <li>The company offers comprehensive trading solutions tailored to meet the diverse needs of its clients. This involves delivering unparalleled, end-to-end trading solutions that cater to various client requirements.</li>
                    </ul>
                    </div>
                    <div style={{textAlign: "left" }}>
                    <h3>Supply Chain Optimization:</h3>
                    <ul>
                        <li>The company empowers clients to optimize their supply chains through innovative and efficient trade practices. This enables clients to streamline their supply chain operations, leading to improved efficiency and cost savings.</li>
                    </ul>
                    </div>
                    <div style={{textAlign: "left" }}>
                    <h3>Risk Mitigation and Value Maximization</h3>
                    <ul>
                        <li>The company assists clients in mitigating risks and maximizing value by providing reliable and strategic services. This includes identifying potential risks and developing strategies to mitigate them, as well as identifying opportunities to increase value and implement strategies to capitalize on these opportunities.</li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
      )
}

export default Vision