import './App.css';
import About from './components/about/About';
import Services from './components/services/Services';
import ChooseUs from './components/chooseus/ChooseUs';
import Process from './components/process/Process';
import OurCustomer from './components/ourcustomer/OurCustomer';
import Footer from './components/footer/Footer';
import Members from './components/members/Members';
import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import Project from './components/project/Project';
import Mission from './components/mission/Mission';
import Vision from './components/mission/Vision';
import Career from './components/career/Career';

function App() {
const handlescroll=(e)=>{
  const element=document.getElementById(e)
  element.scrollIntoView({behavior:"smooth"})
}
  return (
    <div>
      <section id='Home'>
        <Navbar scroll={handlescroll} />
        <Header/>
      </section>
      <section id='About'>
        <About/>
      </section>
      <section id='Mission'>
        <Mission/>
      </section>
      <section id='Vision'>
        <Vision/>
      </section>
      <section id='Services'>
        <Services/>
      </section>
  
     
      <ChooseUs scroll={handlescroll}/>
 
      <section id='Project'>
       <Project/>
      </section>
      <Process/>
    
      <section id='Reviews'>
        <OurCustomer/>
      </section>
      <section id='Career'>
       <Career/>
      </section>
      <section id='Experts'>
       <Members/>
      </section>

    
      <section id='Contact'>
       <Footer/>
      </section>
 
   
    </div>
  );
}

export default App;
