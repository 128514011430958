import React from 'react'
import './services.css'

function Services() {
  return (
    <div>
      <div class="scontainer">
   <h2>
    Our Services
   </h2>
   <h1 style={{color:'#BB9E03'}}>
    What We Provide
   </h1>
   <div class="sservices">
    <div class="sservice-item" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
     <img alt="Close-up image of corn cobs" height="300" src="images/i2.png" width="200"/>
     <p>
     Agri-Retailers
     </p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* 100% increase in income</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* Better input credit recovery</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* One-stop-shop for farmers: seed to harves</p>
    </div>
    <div class="sservice-item" data-aos="flip-up">
     <img alt="Hand holding freshly harvested carrots" height="300" src="images/mission.png" width="200"/>
     <p>
     Farmers
     </p>
     <p style={{fontSize:12,fontWeight:'bold'}}>* 10% higher price realization</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* Better profit margin</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* Faster payment post sale</p>
    </div>
    <div class="sservice-item" data-aos="fade-left">
     <img alt="Various fresh vegetables including carrots and bitter melons" height="300" src="icons/food2.png" width="200"/>
     <p>
     Food Businesses
     </p>
     <p style={{fontSize:12,fontWeight:'bold'}}>* Cheaper pricing</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>*100% faster order fulfilment</p>
      <p style={{fontSize:12,fontWeight:'bold'}}>* Assured quality and convenience</p>
    </div>
    {/* <div class="sservice-item" data-aos="fade-left"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
     <img alt="Close-up image of a cheese wheel with figs and walnuts" height="300" src="https://img.freepik.com/free-photo/green-tea-bud-leaves-green-tea-plantations-morning_335224-955.jpg?size=626&ext=jpg&ga=GA1.1.1041395834.1722601627&semt=ais_hybrid" width="200"/>
     <p>
      Dairy Products
     </p>
    </div> */}
   </div>
  </div>

    </div>
  )
}

export default Services
