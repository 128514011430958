import React from 'react'
import "./mission.css"

const Mission = () => {
  return (
    <div>

    {/* #dfdbca */}
    <div style={{background:"linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 51%, rgba(249,245,240,1) 100%)"}}>
      <div className="ocontainer">
       {/* Our Mission */}
        <div className='column' style={{display:'flex',alignItems:"center",}}>
             <div data-aos="fade-right"  style={{flex:1}}>
                <div className='oheader' style={{display:'flex',alignItems:"center"}}>
                    <img alt="Logo" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width={20} height={40} />
                    <h2 style={{color:'#BB9E03'}}>Our Mission</h2>
                </div>
                <div style={{textAlign: "left" }}>
                <h3>Excellence in Service Delivery</h3>
                <ul>
                    <li>Continuously improve lives of customers through a one-stop-shop with cost-effective solutions.</li>
                    <li>To ensure quick solutions through digitization and advanced technology.</li>
                    <li>Increase farmer's income.</li>
                    <li>Helping Millers, Processors, and Corporates in receiving goods.</li>
                </ul>
                </div>
                <div style={{textAlign: "left" }}>
                <h3>Integrity and Trust</h3>
                <ul>
                    <li>The Smart Grains is committed to upholding the highest standards of integrity, ensuring transparent and trustworthy relationships with clients, partners, and stakeholders.</li>
                </ul>
                </div>
                <div style={{textAlign: "left" }}>
                <h3>Industry Transformation</h3>
                <ul>
                    <li>The Smart Grains aims to be a transformative force in the trading sector, pioneering advancements and setting new benchmarks that elevate the entire industry.</li>
                </ul>
                </div>
            </div>
            <div data-aos="fade-left" style={{flex:1}}>
            <img className='w100' width={290} src='images/i1.png'></img>
            <img className='w100' width={290} src='images/i2.png'></img>
            <img className='w100' width={290} src='images/i3.png'></img>
            </div>
        </div>

    </div>
    </div>
    </div>
  )
}

export default Mission
