import React from 'react'
import "./header.css"

function Header() {
  return (
    <div className='header-box' style={{
      backgroundImage:`url(${'headerbg.webp'})`,
      backgroundSize:'cover',
      backgroundPosition:'center',
      height:'60vh',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      width:'100vw'
    }}>

      <div  style={{
        width:'40%',
        backgroundColor:'#45454594',
        padding:'40px',
        borderRadius:'20px',
        textAlign:'center',
        border:'1px solid white'
        }}>
       
        <h1 className='header-h1'>The Smart Grains</h1>
        <p className='header-p'>At The Smart Grains, we are at the forefront of B2B grain trading, connecting producers and buyers through a seamless, reliable, and efficient platform.</p>
      </div>

      {/* <div style={{width:'40%'}}>
        <img className='header-img' src={"headerimg.png"} alt='img'/>
      </div> */}
      
    </div>
  )
}

export default Header
