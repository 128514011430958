import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useInView } from 'react-intersection-observer';

const Project = () => {
  // Progress bar states initialized to 0 (starts at 0 and progresses dynamically)
  const [progressOne, setProgressOne] = useState(0);
  const [progressTwo, setProgressTwo] = useState(0);
  const [progressThree, setProgressThree] = useState(0);

  // Final values for each progress bar
  const targetProgressOne = 78;
  const targetProgressTwo = 67;
  const targetProgressThree = 84;

  // Intersection Observer for each section
  const { ref: refOne, inView: inViewOne } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: refTwo, inView: inViewTwo } = useInView({ triggerOnce: true, threshold: 0.5 });
  const { ref: refThree, inView: inViewThree } = useInView({ triggerOnce: true, threshold: 0.5 });

  // Function to dynamically update the progress bar values over time
  const animateProgress = (setProgress, targetValue) => {
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      setProgress(progressValue);

      if (progressValue >= targetValue) {
        clearInterval(interval); // Stop the animation when the target value is reached
      }
    }, 20); // Adjust speed (lower is faster)
  };

  // Effect to trigger progress animations when sections come into view
  useEffect(() => {
    if (inViewOne) {
      animateProgress(setProgressOne, targetProgressOne);
    }
    if (inViewTwo) {
      animateProgress(setProgressTwo, targetProgressTwo);
    }
    if (inViewThree) {
      animateProgress(setProgressThree, targetProgressThree);
    }
  }, [inViewOne, inViewTwo, inViewThree]);

  return (
    <div>
      <div className="ocontainer">
        <div className="oheader">
          <img alt="Logo" height="50" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width="50" />
          <h2 style={{color:'#BB9E03'}}>Our Projects</h2>
        </div>

        {/* First Progress Bar Section */}
        <div className='column' style={{ display: "flex", padding: 20 }} ref={refOne}>
          <div style={{ flex: 1 }}>
            <div style={{ width: 150, height: 150, margin: "auto" }}>
              <CircularProgressbar
                value={progressOne}
                text={`${progressOne}%`}
                styles={buildStyles({
                  textColor: '#2a2a2a',
                  pathColor: '#f4a261',
                  trailColor: '#2a2a2a',
                  textSize: '16px',
                })}
              />
            </div>
          </div>
          <div style={{ flex: "2", textAlign: "left" }}>
            <h3>Supporting Farmers and Promoting Sustainable Agriculture</h3>
            <p>The Smart Grains focuses on providing farmers with advanced agricultural technologies and resources. By offering training, The Smart Grains aims to enhance crop yields, reduce costs, and promote sustainable farming practices. This empowers farmers to increase their productivity and income, contributing to overall rural development.</p>
          </div>
        </div>

        {/* Second Progress Bar Section */}
        <div className='column' style={{ display: "flex", padding: 20 }} ref={refTwo}>
          <div style={{ flex: 1 }}>
            <div style={{ width: 150, height: 150, margin: "auto" }}>
              <CircularProgressbar
                value={progressTwo}
                text={`${progressTwo}%`}
                styles={buildStyles({
                  textColor: '#2a2a2a',
                  pathColor: '#f4a261',
                  trailColor: '#2a2a2a',
                  textSize: '16px',
                })}
              />
            </div>
          </div>
          <div style={{ flex: "2", textAlign: "left" }}>
            <h3>Empowering Women in Agriculture:
            </h3>
            <p>The Smart Grains places a strong emphasis on women empowerment by actively involving women in agricultural activities. The project provides women with opportunities for training, leadership roles, and access to financial resources. By supporting women in agriculture, The Smart Grains helps to foster gender equality, improve household incomes, and enhance community well-being.
            </p>
          </div>
        </div>

        {/* Third Progress Bar Section */}
        <div className='column' style={{ display: "flex", padding: 20 }} ref={refThree}>
          <div style={{ flex: 1 }}>
            <div style={{ width: 150, height: 150, margin: "auto" }}>
              <CircularProgressbar
                value={progressThree}
                text={`${progressThree}%`}
                styles={buildStyles({
                  textColor: '#2a2a2a',
                  pathColor: '#f4a261',
                  trailColor: '#2a2a2a',
                  textSize: '16px',
                })}
              />
            </div>
          </div>
          <div style={{ flex: "2", textAlign: "left" }}>
            <h3>Community Development and Inclusive Growth</h3>
            <p>The Smart Grains is dedicated to fostering inclusive growth within communities. The project focuses on building resilient agricultural systems that support all community members, including marginalized groups. Through initiatives like cooperative farming, microfinance, and education programs, The Smart Grains aims to create a self-sustaining ecosystem where all participants can thrive, leading to long-term social and economic benefits.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
