import React from 'react'
import './members.css';

function Members() {
  return (
    <div className='mbody'>
      <div class="mheader">
   <img alt="Logo" height="50" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width="50"/>
   <h1 style={{color:'#BB9E03'}}>
    Meet Expert Members
   </h1>
  </div>

<div class="members">

<div class="member">
    <img alt="Mike Brown holding fresh vegetables in a farm" height="200" src='images/avatar.png' width="200"/>
    <div class="member-info">
     <h3>
      Alka Singh
     </h3>
     <p style={{marginBottom:20}}>
      Director 
     </p>
     {/* <p>
     "I believe that empowering farmers through technology is key to a prosperous future. Together, we can build stronger communities and a more sustainable agricultural ecosystem."
     </p> */}
     
    </div>
   </div>

</div>
  


  <div class="members">
   


   <div class="member">
    <img alt="Alees Hardson working in a greenhouse" height="300" src="images/avatar.png" width="250"/>
    <div class="member-info">
     <h3>
     Sachin Singh
     </h3>
     <p style={{marginBottom:20}}>
     Business Head 
     </p>
     {/* <p>
     "Our goal is to simplify the trading process, ensuring farmers receive fair prices and traders access quality produce. Collaboration and trust are the cornerstones of our success."
     </p>
      */}
    </div>
   </div>

   <div class="member">
    <img alt="Alees Hardson working in a greenhouse" height="300" src="icons/t1.jpg" width="250"/>
    <div class="member-info">
     <h3>
      Sudheer Gaikwad
     </h3>
     <p style={{marginBottom:20}}>
      Manager-Trade 
     </p>
     {/* <p>
     "Our goal is to simplify the trading process, ensuring farmers receive fair prices and traders access quality produce. Collaboration and trust are the cornerstones of our success."
     </p>
      */}
    </div>
   </div>
   <div class="member">
    <img alt="Alees Hardson working in a greenhouse" height="300" src="images/img3.jpg" width="250"/>
    <div class="member-info">
     <h3>
      Goutam Kumar Dey

     </h3>
     <p style={{marginBottom:20}}>
      Head-Quality 
     </p>
     {/* <p>
     "As the Quality Head of The Smart Grains, my commitment is to ensure that every product we deliver meets the highest standards of excellence. Your trust in us drives our pursuit of quality in every grain, every transaction."
     </p> */}
     
    </div>
   </div>
   {/* <div class="member">
    <img alt="Polard Albert standing in a field" height="300" src="https://media.istockphoto.com/id/1369199360/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=612x612&w=0&k=20&c=ujyGdu8jKI2UB5515XZA33Tt4DBhDU19dKSTUTMZvrg=" width="250"/>
    <div class="member-info">
     <h3>
      Polard Albert
     </h3>
     <p>
      Expert 
     </p>
     
    </div>
   </div> */}
   {/* <div class="member">
    <img alt="Jassica Andrew holding grapes in a vineyard" height="300" src="https://img.freepik.com/free-photo/brunette-businesswoman-posing_23-2148142728.jpg" width="250"/>
    <div class="member-info">
     <h3>
      Jassica Andrew
     </h3>
     <p>
      Expert 
     </p>
     
    </div>
   </div> */}
  </div>
    </div>
  )
}

export default Members
