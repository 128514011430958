import React from 'react'
import './ourcustomer.css'

function OurCustomer() {
  return (
    <div>
      <div class="ocontainer">
   <div class="oheader">
    <img alt="Logo" height="50" src="https://cdn-icons-png.flaticon.com/128/346/346195.png" width="50"/>
    <h2 style={{color:'#BB9E03'}}>
     What Our Customer Says
    </h2>
   </div>
   <div class="testimonials">
    <div class="testimonial">
     <div class="quote">
      <i class="fas fa-quote-left">
      </i>
     </div>
     <div class="stars">
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
     </div>
     <h3>
    Review
     </h3>
     <p>
     "I've seen a significant increase in my yields and profits since partnering with this company, and I'm confident their innovative solutions will continue to drive growth in the agricultural industry ."
     </p>
     {/* <img alt="Tomatoes on a vine" height="100" src="headerlogo2.png" width="100"/> */}
     <hr/>
     <div class="customer">
      <img alt="Customer profile picture" height="40" src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg" width="40"/>
      <div>
       <div class="name">
        Indrajeet Ajeet
       </div>
       <div class="role">
        Trader
       </div>
      </div>
     </div>
    </div>
    <div class="testimonial">
     <div class="quote">
      <i class="fas fa-quote-left">
      </i>
     </div>
     <div class="stars">
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
     </div>
     <h3>
      Review
     </h3>
     <p>
     "The Smart Grains never disappoints! Their commitment to sustainability and customer support has been impressive, and I look forward to continuing our successful partnership for years to come."
     </p>
     {/* <img alt="Hand holding freshly picked carrots" height="100" src="headerlogo2.png" width="100"/> */}
     <hr/>
     <div class="customer">
      <img alt="Customer profile picture" height="40" src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg" width="40"/>
      <div>
       <div class="name">
        Harish Kumar Bhardwaj
       </div>
       <div class="role">
        Director- Mountreach Organic Artifacts Pvt Ltd
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
    </div>
  )
}

export default OurCustomer
